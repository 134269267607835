import {CatalogReference} from '@wix/ecom_current-cart';

export class CatalogReferenceModel {
  public catalogItemId: string;
  public appId: string;

  constructor(params: CatalogReferenceModel) {
    this.catalogItemId = params.catalogItemId;
    this.appId = params.appId;
  }

  public static fromGQL(catalogItemId: string, appId: string) {
    return new CatalogReferenceModel({
      catalogItemId,
      appId,
    });
  }

  public static fromSDK(catalogReference: CatalogReference) {
    return new CatalogReferenceModel({
      catalogItemId: catalogReference?.catalogItemId,
      appId: catalogReference?.appId,
    });
  }
}
