import {
  StoreMetaDataApi,
  PurchaseFlowPropertiesService,
  IPurchaseFlowProperties,
} from '@wix/wixstores-client-storefront-sdk';
import {PAYPAL_METHOD_NAME, PaymentMethodType} from '../../common/constants';
import {PaymentMethod} from '@wix/wixstores-graphql-schema-node';

export class StoreMetaDataService {
  private readonly purchaseFlowPropertiesService: PurchaseFlowPropertiesService;
  private readonly purchaseFlowPropertiesPromise?: Promise<IPurchaseFlowProperties>;
  private ecommerceSettings?: {
    hasValidationsInCart: boolean;
    hasAdditionalFees: boolean;
  };

  constructor({purchaseFlowPropertiesService}: {purchaseFlowPropertiesService: PurchaseFlowPropertiesService}) {
    this.purchaseFlowPropertiesService = purchaseFlowPropertiesService;
    this.purchaseFlowPropertiesPromise = this.purchaseFlowPropertiesService.getPurchaseFlowProperties();
  }

  public async get(): Promise<ReturnType<StoreMetaDataApi['getStoreMetaData']>> {
    /* istanbul ignore else */
    if (this.purchaseFlowPropertiesPromise !== undefined) {
      return (await this.purchaseFlowPropertiesPromise).storeMetaData;
    }
  }

  public async init(options?: {shouldWarmupData: boolean}) {
    try {
      this.ecommerceSettings = await this.purchaseFlowPropertiesService.getEcommerceSettings(options);
    } catch {
      this.ecommerceSettings = undefined;
    }
  }

  public getEcommerceSettings() {
    return this.ecommerceSettings ?? {hasValidationsInCart: true, hasAdditionalFees: true};
  }

  private async getActivePaymentMethods() {
    const storeMetaData = await this.get();
    return storeMetaData?.activePaymentMethods ?? [];
  }

  private async getRegularFlowPaymentMethods(): Promise<PaymentMethod[]> {
    const activePaymentMethods = await this.getActivePaymentMethods();
    return activePaymentMethods.filter(({name}) => name !== PAYPAL_METHOD_NAME);
  }

  private async getEWalletPaymentMethods(): Promise<PaymentMethod[]> {
    const activePaymentMethods = await this.getActivePaymentMethods();
    return activePaymentMethods.filter((paymentMethod) => paymentMethod.type === PaymentMethodType.eWallet);
  }

  private async getOfflinePaymentMethods(): Promise<PaymentMethod[]> {
    const activePaymentMethods = await this.getActivePaymentMethods();
    return activePaymentMethods.filter((paymentMethod) => paymentMethod.type === PaymentMethodType.offline);
  }

  public async hasRegularFlowPaymentMethods() {
    return !!(await this.getRegularFlowPaymentMethods()).length;
  }

  public async hasAnyPaymentMethods() {
    return !!(await this.getActivePaymentMethods()).length;
  }

  public async hasEWalletPaymentMethods() {
    return !!(await this.getEWalletPaymentMethods()).length;
  }

  public async hasOfflinePaymentMethods() {
    return !!(await this.getOfflinePaymentMethods()).length;
  }

  public async getPaymentMethodsNames() {
    const activePaymentMethods = await this.getActivePaymentMethods();
    return activePaymentMethods.map((method) => method.name);
  }
}
