export enum FedopsInteractions {
  UpdateQuantity = 'update-quantity-interaction',
  RemoveItem = 'remove-item-interaction',
  ApplyCoupon = 'apply-coupon-interaction',
  CheckoutButtonClicked = 'checkout-button-clicked',
  lineItemsLoaded = 'line-items-loaded',
  padeLoaded = 'page-loaded',
  errorInEstimateTotalsWithShipping = 'error-in-estimate-totals-with-shipping',
  createCheckout = 'create-checkout',
  createCheckoutWithError = 'create-checkout-with-error',
  createCheckoutOld = 'create-checkout-old',
  createCheckoutOldWithError = 'create-checkout-old-with-error',
}

export const CART_COMMANDS_URL =
  '/_api/wix-ecommerce-renderer-web/store-front/cart/{commandName}?returnCartSummary=false';

export const EMPTY_CART_ID = '00000000-000000-000000-000000000000';

export enum CreateCheckoutExceptions {
  siteMustAcceptPayments = 'SITE_MUST_ACCEPT_PAYMENTS_TO_CREATE_CHECKOUT',
  unrecognizedError = 'UNRECOGNIZED_ERROR',
  noCheckoutId = 'NO_CHECKOUT_ID',
}

export const SEPERATOR = ' - ';

export enum StyleParam {
  ShowContinueShopping = 'cart_showContinueShopping',
  CornerRadius = 'cartButton_cornersRadius',
  dynamicPaymentMethodsTheme = 'cartButton_dynamicPaymentMethodsTheme',
  dynamicPaymentMethodsShape = 'cartButton_dynamicPaymentMethodsShape',
  SelectedSkin = 'cartButton_selectedSkin',
  ShowCoupon = 'cart_showCoupon',
  ShowExpressCheckout = 'cart_showExpressCheckout',
  ShowBuyerNote = 'cart_showBuyerNote',
  ShowTax = 'cart_showTax',
  ShowShipping = 'cart_showShipping',
  Responsive = 'responsive',
  CheckoutButtonsPosition = 'mobile:cart_checkoutButtonsPosition',
  ShowCheckoutButton = 'CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY',
  ShowGoToCartButton = 'CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY',
  CartItemQuantityCounterVisibility = 'CART_ITEM_QUANTITY_COUNTER_VISIBILITY',
  CartItemImageVisibility = 'CART_ITEM_IMAGE_VISIBILITY',
  CartItemInfo = 'CART_ITEM_INFO',
  CartSummaryDisclaimerVisibility = 'CART_SUMMARY_DISCLAIMER_VISIBILITY',
  CartSummarySecureBadgeVisibility = 'CART_SUMMARY_SECURE_BADGE_VISIBILITY',
  cartTitleVisibility = 'CART_TITLE_VISIBILITY',
  cartNumberOfCartItemsVisibility = 'CART_NUMBER_OF_CART_ITEMS_VISIBILITY',
}

export const BI_APP_NAME = 'Stores';

export const CART_ORIGIN = 'shopping cart';
export const SIDE_CART_ORIGIN = 'side cart';

export enum ContinueShoppingBiOrigin {
  shoppingCart = 'shopping cart',
  minimumOrder = 'minimum_order_cart',
}

export enum OriginTypes {
  Paypal = 'paypal',
  AddToCart = 'addToCart',
}

export const PAYPAL_METHOD_NAME = 'PayPal';

export const MINIMUM_ORDER_AMOUNT_NOT_REACHED = 'MINIMUM_ORDER_AMOUNT_NOT_REACHED';

export enum PaymentMethodType {
  creditCard = 'CREDIT_CARD',
  eWallet = 'HOSTED',
  offline = 'OFFLINE',
}

export enum ShippingMethodType {
  NONE = 'none',
  PICKUP = 'store pickup',
  SHIPPING = 'shipping',
}

export enum SlotsMap {
  mainBottom = 'cart-page:main:after-1',
}
