import {IShippingOption} from '../../types/app.types';
import {LogisticsModel} from './Logistics.model';
import {CostModel} from './Cost.model';

export class ShippingOptionModel {
  public id: string;
  public title: string;
  public logistics: LogisticsModel;
  public cost: CostModel;

  constructor(params: ShippingOptionModel) {
    this.id = params.id;
    this.title = params.title;
    this.logistics = params.logistics;
    this.cost = params.cost;
  }

  public static fromGQL(shippingOption: IShippingOption) {
    return new ShippingOptionModel({
      id: shippingOption?.id,
      title: shippingOption?.title,
      logistics: LogisticsModel.fromGQL(
        shippingOption?.pickupInfo,
        shippingOption?.deliveryTimeSlot,
        shippingOption?.deliveryTime
      ),
      cost: shippingOption?.formattedRate
        ? CostModel.fromGQL({
            formattedConvertedAmount: shippingOption?.formattedRate,
          })
        : undefined,
    });
  }
}
