import {IViolationLineItemTarget, IViolationNameInLineItem} from '../../types/app.types';
import {TargetLineItem, NameInLineItem} from '@wix/ecom_current-cart';

export class ViolationLineItemTargetModel {
  public id: string;
  public name?: ViolationLineItemTargetName;

  constructor(params: ViolationLineItemTargetModel) {
    this.id = params.id;
    this.name = params.name;
  }

  public static fromGQL(lineItemTarget?: IViolationLineItemTarget) {
    return new ViolationLineItemTargetModel({
      id: lineItemTarget.id,
      name: lineItemTarget?.name && gqlToViolationLineItemTargetName(lineItemTarget.name),
    });
  }

  public static fromSDK(lineItemTarget?: TargetLineItem) {
    return new ViolationLineItemTargetModel({
      id: lineItemTarget._id,
      name: lineItemTarget?.name && sdkToViolationLineItemTargetName(lineItemTarget.name),
    });
  }
}

export enum ViolationLineItemTargetName {
  default = 'LINE_ITEM_DEFAULT',
}

const gqlToViolationLineItemTargetName = (name: IViolationNameInLineItem): ViolationLineItemTargetName => {
  const map: Record<IViolationNameInLineItem, ViolationLineItemTargetName> = {
    LINE_ITEM_DEFAULT: ViolationLineItemTargetName.default,
  };

  return map[name];
};

const sdkToViolationLineItemTargetName = (name: NameInLineItem): ViolationLineItemTargetName => {
  const map: Record<NameInLineItem, ViolationLineItemTargetName> = {
    [NameInLineItem.LINE_ITEM_DEFAULT]: ViolationLineItemTargetName.default,
  };

  return map[name];
};
