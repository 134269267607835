import {IDiscountRule} from '../../types/app.types';

export class DiscountRuleModel {
  public name: string;

  constructor(params: DiscountRuleModel) {
    this.name = params.name;
  }

  public static fromGQL(discountRule: IDiscountRule) {
    return new DiscountRuleModel({name: discountRule?.name});
  }

  public static fromSDK(discountRuleName: string) {
    return new DiscountRuleModel({name: discountRuleName});
  }
}
