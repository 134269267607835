import {IViolationNameInOther, IViolationOtherTarget} from '../../types/app.types';
import {NameInOther, Other} from '@wix/ecom_current-cart';

export class ViolationOtherTargetModel {
  public name?: ViolationOtherTargetName;

  constructor(params: ViolationOtherTargetModel) {
    this.name = params.name;
  }

  public static fromGQL(otherTarget?: IViolationOtherTarget): ViolationOtherTargetModel {
    return new ViolationOtherTargetModel({
      name: otherTarget?.name && gqlToViolationOtherTargetName(otherTarget.name),
    });
  }

  public static fromSDK(otherTarget: Other): ViolationOtherTargetModel {
    return new ViolationOtherTargetModel({
      name: otherTarget?.name && sdkToViolationOtherTargetName(otherTarget.name),
    });
  }
}

export enum ViolationOtherTargetName {
  default = 'OTHER_DEFAULT',
  delivery = 'DELIVERY',
}

const gqlToViolationOtherTargetName = (name: IViolationNameInOther): ViolationOtherTargetName => {
  const map: Record<IViolationNameInOther, ViolationOtherTargetName> = {
    OTHER_DEFAULT: ViolationOtherTargetName.default,
    DELIVERY: ViolationOtherTargetName.delivery,
  };

  return map[name];
};

const sdkToViolationOtherTargetName = (name: NameInOther): ViolationOtherTargetName => {
  const map: Record<NameInOther, ViolationOtherTargetName> = {
    [NameInOther.OTHER_DEFAULT]: ViolationOtherTargetName.default,
  };

  return map[name];
};
