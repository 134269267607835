import {ShippingOptionModel} from '../models/ShippingOption.model';

export type ShippingRuleOptionModel = ShippingOptionModel & {isPickup: boolean};

export function toShippingRuleOptionModel(option: ShippingOptionModel): ShippingRuleOptionModel {
  return {
    ...option,
    isPickup: !!option.logistics?.pickupDetails?.address?.addressLine,
  };
}
