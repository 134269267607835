import {IMinimumOrderAmount} from '../../types/app.types';
import {PriceModel} from './Price.model';
import {ApplicationError} from '@wix/ecom_current-cart';
import {MINIMUM_ORDER_AMOUNT_NOT_REACHED} from '../../common/constants';

export class MinimumOrderAmountModel {
  public reached: boolean;
  public minAmount: PriceModel;
  public additionalAmount: PriceModel;

  constructor(params: MinimumOrderAmountModel) {
    this.reached = params.reached;
    this.minAmount = params.minAmount;
    this.additionalAmount = params.additionalAmount;
  }

  public static fromGQL(minimumOrderAmount: IMinimumOrderAmount) {
    return new MinimumOrderAmountModel({
      reached: minimumOrderAmount.reached,
      minAmount: PriceModel.fromGQL({
        convertedAmount: minimumOrderAmount.convertedValue,
        formattedConvertedAmount: minimumOrderAmount.formattedConvertedValue,
      }),
      additionalAmount: PriceModel.fromGQL({
        convertedAmount: minimumOrderAmount.convertedDiff,
        formattedConvertedAmount: minimumOrderAmount.formattedConvertedDiff,
      }),
    });
  }

  public static fromSDK(orderValidationErrors: ApplicationError[]) {
    const minimumOrderError = orderValidationErrors?.find(
      (validationError) => validationError.code === MINIMUM_ORDER_AMOUNT_NOT_REACHED
    );
    if (!minimumOrderError) {
      return new MinimumOrderAmountModel({
        reached: true,
        minAmount: undefined,
        additionalAmount: undefined,
      });
    }
    return new MinimumOrderAmountModel({
      reached: false,
      additionalAmount: PriceModel.fromSDK(minimumOrderError.data.remaining),
      minAmount: PriceModel.fromSDK(minimumOrderError.data.minimumOrderAmount),
    });
  }
}
