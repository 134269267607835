import {IPickupDetails} from '../../types/app.types';
import {AddressModel} from './Address.model';
import {PickupMethod} from '@wix/wixstores-client-storefront-sdk';

export class PickupDetailsModel {
  public address: AddressModel;
  public pickupMethod: PickupMethod;

  constructor(params: PickupDetailsModel) {
    this.address = params.address;
    this.pickupMethod = params.pickupMethod;
  }

  public static fromGQL(pickupDetails: IPickupDetails) {
    return new PickupDetailsModel({
      address: pickupDetails?.address
        ? AddressModel.fromGQL(pickupDetails?.address)
        : /* istanbul ignore next */ undefined,
      pickupMethod: pickupDetails?.pickupMethod ?? undefined,
    });
  }
}
