import {IViolation, IViolationSeverity} from '../../types/app.types';
import {ViolationTargetModel} from './ViolationTarget.model';
import {Violation, Severity} from '@wix/ecom_current-cart';

export class ViolationModel {
  public severity: ViolationSeverity;
  public target: ViolationTargetModel;
  public description: string;

  constructor(params: ViolationModel) {
    this.severity = params.severity;
    this.target = params.target;
    this.description = params.description;
  }

  public static fromGQL(violation: IViolation) {
    return new ViolationModel({
      severity: gqlToSeverity(violation.severity),
      target: ViolationTargetModel.fromGQL(violation.target),
      description: violation.description,
    });
  }

  public static fromSDK(violation: Violation) {
    return new ViolationModel({
      severity: sdkToSeverity(violation.severity),
      target: ViolationTargetModel.fromSDK(violation.target),
      description: violation.description,
    });
  }
}

const sdkToSeverity = (severity: Severity) => {
  const map: Record<Severity, ViolationSeverity> = {
    [Severity.ERROR]: ViolationSeverity.error,
    [Severity.WARNING]: ViolationSeverity.warning,
  };

  return map[severity];
};

const gqlToSeverity = (severity: IViolationSeverity): ViolationSeverity => {
  const map: Record<IViolationSeverity, ViolationSeverity> = {
    ERROR: ViolationSeverity.error,
    WARNING: ViolationSeverity.warning,
  };

  return map[severity];
};

export enum ViolationSeverity {
  error = 'ERROR',
  warning = 'WARNING',
}
