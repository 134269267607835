import {ShippingOptionModel} from './ShippingOption.model';
import {IShippingRule} from '../../types/app.types';

export class ShippingRuleModel {
  public id: string;
  public shippingOptions: ShippingOptionModel[];

  constructor(params: ShippingRuleModel) {
    this.id = params.id;
    this.shippingOptions = params.shippingOptions;
  }

  public static fromGQL(shippingRule: IShippingRule) {
    return new ShippingRuleModel({
      id: shippingRule?.id ?? '',
      shippingOptions: shippingRule?.options.map((option) => ShippingOptionModel.fromGQL(option)),
    });
  }
}
