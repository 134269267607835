import {IDeliveryTimeSlot, IPickupDetails} from '../../types/app.types';
import {PickupDetailsModel} from './PickupDetails.model';
import {DeliveryTimeSlotModel} from './DeliveryTimeSlot.model';

export class LogisticsModel {
  public pickupDetails: PickupDetailsModel;
  public deliveryTimeSlot: DeliveryTimeSlotModel;
  public deliveryTime: string;

  constructor(params: LogisticsModel) {
    this.pickupDetails = params.pickupDetails;
    this.deliveryTimeSlot = params.deliveryTimeSlot;
    this.deliveryTime = params.deliveryTime;
  }

  public static fromGQL(pickupDetails: IPickupDetails, deliveryTimeSlot: IDeliveryTimeSlot, deliveryTime: string) {
    return new LogisticsModel({
      pickupDetails: pickupDetails ? PickupDetailsModel.fromGQL(pickupDetails) : undefined,
      deliveryTimeSlot: deliveryTimeSlot ? DeliveryTimeSlotModel.fromGQL(deliveryTimeSlot) : undefined,
      deliveryTime,
    });
  }
}
