import {IViolationTarget} from '../../types/app.types';
import {ViolationLineItemTargetModel} from './ViolationLineItemTarget.model';
import {ViolationOtherTargetModel} from './ViolationOtherTarget.model';
import {Target} from '@wix/ecom_current-cart';

export class ViolationTargetModel {
  public lineItemTarget?: ViolationLineItemTargetModel;
  public otherTarget?: ViolationOtherTargetModel;

  constructor(params: ViolationTargetModel) {
    this.lineItemTarget = params.lineItemTarget;
    this.otherTarget = params.otherTarget;
  }

  public static fromGQL(target: IViolationTarget) {
    return new ViolationTargetModel({
      lineItemTarget: target?.lineItem && ViolationLineItemTargetModel.fromGQL(target.lineItem),
      otherTarget: target?.other && ViolationOtherTargetModel.fromGQL(target.other),
    });
  }

  public static fromSDK(target: Target) {
    return new ViolationTargetModel({
      lineItemTarget: target?.lineItem && ViolationLineItemTargetModel.fromSDK(target.lineItem),
      otherTarget: target?.other && ViolationOtherTargetModel.fromSDK(target.other),
    });
  }
}
