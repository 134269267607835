export class UrlModel {
  public url: string;
  public relativePath?: string;

  constructor(params: UrlModel) {
    this.url = params.url;
    this.relativePath = params?.relativePath;
  }

  public static fromGQL(urlPart: string, pageUrl: string) {
    return new UrlModel({
      relativePath: urlPart,
      url: pageUrl,
    });
  }

  public static fromSDK(url: string) {
    return new UrlModel({
      url,
    });
  }
}
