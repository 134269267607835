import {IDestination} from '../../types/app.types';
import {AddressModel} from './Address.model';
import {AddressWithContact} from '@wix/ecom_current-cart';

export class ContactInfoModel {
  public address: AddressModel;

  constructor(params: ContactInfoModel) {
    this.address = params.address;
  }

  public static fromGQL(destination: IDestination) {
    return new ContactInfoModel({address: AddressModel.fromGQL(destination)});
  }

  public static fromSDK(contactInfo: AddressWithContact) {
    return new ContactInfoModel({address: AddressModel.fromSDK(contactInfo?.address)});
  }
}
