import {IDeliveryTimeSlot} from '../../types/app.types';

export class DeliveryTimeSlotModel {
  public from: string;
  public to: string;

  constructor(params: DeliveryTimeSlotModel) {
    this.from = params.from;
    this.to = params.to;
  }

  public static fromGQL(deliveryTimeSlot: IDeliveryTimeSlot) {
    return new DeliveryTimeSlotModel({
      from: deliveryTimeSlot?.from,
      to: deliveryTimeSlot?.to,
    });
  }
}
