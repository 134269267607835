/* istanbul ignore file */
import {ShippingInformation, ShippingOption, CarrierErrors} from '@wix/ecom_current-cart';
import _ from 'lodash';

const COST_AMOUNT = 'cost.price.amount';
const COST_CONVERTED_AMOUNT = 'cost.price.convertedAmount';
const COST_FORMATTED_AMOUNT = 'cost.price.formattedAmount';
const COST_FORMATTED_CONVERTED_AMOUNT = 'cost.price.formattedConvertedAmount';

function getShippingOptions({
  shippingInfo,
  invalidCarrierIds = [],
}: {
  shippingInfo: ShippingInformation;
  invalidCarrierIds?: string[];
}) {
  const validCarriers =
    (invalidCarrierIds.length
      ? shippingInfo?.carrierServiceOptions?.filter((c) => !invalidCarrierIds.includes(c.carrierId))
      : shippingInfo?.carrierServiceOptions) || [];
  const checkoutShippingRuleOptions = validCarriers
    .flatMap((carrierServiceOption) => carrierServiceOption.shippingOptions)
    .map((shippingOption: ShippingOption) => {
      const countryKey = _.get(shippingOption, 'logistics.pickupDetails.address.country');
      const country = countryKey;
      const ratePrice = {
        amount: parseFloat(_.get(shippingOption, COST_AMOUNT)),
        formattedAmount: _.get(shippingOption, COST_FORMATTED_AMOUNT),
      };
      const convertedRatePrice = {
        amount: parseFloat(_.get(shippingOption, COST_CONVERTED_AMOUNT)),
        formattedAmount: _.get(shippingOption, COST_FORMATTED_CONVERTED_AMOUNT),
      };

      return {
        id: _.get(shippingOption, 'code'),
        title: _.get(shippingOption, 'title'),
        rate: ratePrice.amount,
        formattedRate: ratePrice.formattedAmount,
        convertedRate: convertedRatePrice.amount,
        convertedFormattedRate: convertedRatePrice.formattedAmount,
        deliveryTime: _.get(shippingOption, 'logistics.deliveryTime', ''),
        pickupInfo: _.get(shippingOption, 'logistics.pickupDetails', null) && {
          pickupMethod: _.get(shippingOption, 'logistics.pickupDetails.pickupMethod'),
          address: _.get(shippingOption, 'logistics.pickupDetails.address') && {
            country: country || '',
            subdivision: _.get(shippingOption, 'logistics.pickupDetails.address.subdivision', null),
            addressLine: _.get(shippingOption, 'logistics.pickupDetails.address.addressLine1', ''), // TODO: in original GQL code this was logistics.pickupDetails.address.addressLine, need to check why
            city: _.get(shippingOption, 'logistics.pickupDetails.address.city', ''),
            zipCode: _.get(shippingOption, 'logistics.pickupDetails.address.postalCode', ''),
            countryName: _.get(shippingOption, 'logistics.pickupDetails.address.countryFullname', ''),
            subdivisionName: _.get(shippingOption, 'logistics.pickupDetails.address.subdivisionFullname', null),
          },
          instructions: _.get(shippingOption, 'logistics.instructions'),
        },
        deliveryTimeSlot: _.get(shippingOption, 'logistics.deliveryTimeSlot'),
      };
    });

  checkoutShippingRuleOptions.sort(sortCheckoutShippingRuleOptions);
  return _.compact(movePickUpCheckoutShippingRuleOptionToEnd(checkoutShippingRuleOptions));
}

function getShippingRule({
  shippingInfo,
  invalidCarrierIds,
}: {
  shippingInfo: ShippingInformation;
  invalidCarrierIds: string[];
}) {
  return {
    id: shippingInfo?.region?._id,
    ruleType: '',
    options: getShippingOptions({shippingInfo, invalidCarrierIds}),
  };
}

function sortCheckoutShippingRuleOptions(firstCheckoutShippingRuleOption, secondCheckoutShippingRuleOption) {
  return firstCheckoutShippingRuleOption.rate - secondCheckoutShippingRuleOption.rate;
}

function movePickUpCheckoutShippingRuleOptionToEnd(checkoutShippingRuleOptions) {
  return [
    ...checkoutShippingRuleOptions.filter((rule) => !rule.pickupInfo),
    ...checkoutShippingRuleOptions.filter((rule) => !!rule.pickupInfo),
  ];
}

function isShippingRuleValid(carrierErrors: any[], isExpectedError: boolean, canShipToDestination: boolean) {
  if (carrierErrors.length) {
    return !isExpectedError && canShipToDestination;
  } else {
    return canShipToDestination;
  }
}

function getCarriersErrorFields(carrierErrors: CarrierErrors): {
  carrierId: string;
  field: string;
}[] {
  const errors = carrierErrors?.errors;

  if (!errors) {
    return [];
  }

  return _.compact(
    _.map(errors, (error) => {
      const field = error.error?.validationError?.fieldViolations?.[0]?.field;
      if (!field) {
        return;
      } else {
        return {carrierId: error?.carrierId, field};
      }
    })
  );
}

export function getShippingRuleInfo(shippingInfo: ShippingInformation, carrierErrors: CarrierErrors) {
  let canShipToDestination = !!shippingInfo?.selectedCarrierServiceOption;
  let status = canShipToDestination ? 'OK' : 'NoShipppingToDestination';
  const carriersErrorFields = getCarriersErrorFields(carrierErrors);
  let isExpectedError = false;

  if (carriersErrorFields.length) {
    const errorFields = carriersErrorFields.map((e) => e.field);

    if (errorFields.includes('shippingDestination')) {
      status = 'FullAddressRequired';
      canShipToDestination = true;
      isExpectedError = true;
    } else if (errorFields.includes('postalCode')) {
      status = 'MissingZipCode';
      canShipToDestination = true;
      isExpectedError = true;
    } else if (errorFields.includes('shippingDestination.subdivision')) {
      status = 'UnsupportedRegion';
      canShipToDestination = false;
      isExpectedError = true;
    }
  }

  if (!canShipToDestination && !shippingInfo) {
    return null;
  }

  const carrierServiceOptions = shippingInfo.carrierServiceOptions;
  const shippingRuleValid = isShippingRuleValid(carriersErrorFields, isExpectedError, canShipToDestination);

  return (
    carrierServiceOptions && {
      shippingRule: shippingRuleValid
        ? getShippingRule({
            shippingInfo,
            invalidCarrierIds: carriersErrorFields.map((c) => c.carrierId),
          })
        : null,
      canShipToDestination,
      status,
    }
  );
}
