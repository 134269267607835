import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from '../specs';
import {APP_DEFINITION_ID} from '@wix/wixstores-client-core';

export function hackInitializeViewerScriptForPreviewBecausePreviewIsInIframe(siteStore: SiteStore) {
  if (siteStore.isPreviewMode() && siteStore.experiments.enabled(SPECS.FixLoadingCartInEditorAndPreview)) {
    return loadEcomPlatformViewerScriptIfNotLoadedYet(siteStore);
  }
}

function loadEcomPlatformViewerScriptIfNotLoadedYet(siteStore: SiteStore) {
  return siteStore.siteApis.getPublicAPI(APP_DEFINITION_ID);
}
