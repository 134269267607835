import {CartType, ProductType, ShippingRuleStatus} from '@wix/wixstores-client-core';
import type {ICart as ICartFromCartApi, ICurrentCartService, SiteStore} from '@wix/wixstores-client-storefront-sdk';
// eslint-disable-next-line import/no-deprecated
import {CheckoutApi, intToGuid} from '@wix/wixstores-client-storefront-sdk';
import {CreateCheckoutExceptions, FedopsInteractions} from '../../common/constants';
import {
  ApplicationError,
  CartLayout,
  ICartControllerApi,
  IOptionsSelectionsValue,
  IRenderingConfig,
} from '../../types/app.types';
import {BIService} from './BIService';
import {StyleSettingsService} from './StyleSettingsService';
import {SPECS} from '../specs';
import {CartModel} from '../models/Cart.model';
import {LineItemModel} from '../models/LineItem.model';
import {EstimatedTotalsModel} from '../models/EstimatedTotals.model';
import {ViolationSeverity} from '../models/Violation.model';
import {Cart, DeliveryLogistics, EstimateTotalsResponse, MultiCurrencyPrice} from '@wix/ecom_current-cart';
import {StoreMetaDataService} from './StoreMetaDataService';
import {ChannelType} from '@wix/auto_sdk_ecom_current-cart';
import {toShippingRuleOptionModel} from '../utils/shipping';

type CartWithPrivateFields = Cart & {
  subtotalAfterDiscounts: MultiCurrencyPrice;
  subtotal: MultiCurrencyPrice;
  discount: MultiCurrencyPrice;
};
type DeliveryLogisticsWithPrivateFields = DeliveryLogistics & {deliveryTimeSlot: string};

const bookingsAppId = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';

export type CouponError = {
  code: string;
  message: string;
};

type IEnrichedData = {
  renderingConfigMap: Record<string, IRenderingConfig>;
  bookingsOptionsMap: Record<string, IOptionsSelectionsValue[]>;
};

export class CartService {
  private readonly siteStore: SiteStore;
  private readonly biService: BIService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly checkoutApi: CheckoutApi;
  private readonly currentCartService: ICurrentCartService;
  private readonly storeMetaDataService: StoreMetaDataService;
  public readonly origin: string;
  public readonly cartLayout: CartLayout;
  public couponError: CouponError = null;
  public cartModel: CartModel;
  public estimatedTotals: EstimatedTotalsModel;
  public checkoutId: string;
  public hasError: boolean;
  public isSummaryUpdating: boolean = false;
  public isSummaryLoading: boolean = false;
  public controllerApi: ICartControllerApi;
  public loadingItems: number[] = [];
  public errorOnEstimateTotals: boolean = false;

  constructor({
    siteStore,
    biService,
    styleSettingsService,
    currentCartService,
    origin,
    controllerApi,
    storeMetaDataService,
    cartLayout,
  }: {
    controllerApi: ICartControllerApi;
    siteStore: SiteStore;
    biService: BIService;
    styleSettingsService: StyleSettingsService;
    currentCartService: ICurrentCartService;
    origin: string;
    storeMetaDataService: StoreMetaDataService;
    cartLayout: CartLayout;
  }) {
    this.siteStore = siteStore;
    this.biService = biService;
    this.styleSettingsService = styleSettingsService;
    this.currentCartService = currentCartService;
    this.origin = origin;
    this.checkoutApi = new CheckoutApi({siteStore, origin});
    this.controllerApi = controllerApi;
    this.storeMetaDataService = storeMetaDataService;
    this.cartLayout = cartLayout;
  }

  public clearLoadingItems = () => {
    this.loadingItems = [];
  };

  public showLoaderOnItem = (cartItemId: number) => {
    this.loadingItems.push(cartItemId);
    this.isSummaryUpdating = true;
  };

  private hasBookingsItem(cart: Cart) {
    return cart?.lineItems.some((lineItem) => lineItem?.catalogReference?.appId === bookingsAppId);
  }

  private async getEnrichedData(): Promise<IEnrichedData> {
    const cartGql = await this.getCartGqlForBookings();
    const renderingConfigMap = cartGql.items.reduce((acc, item) => {
      acc[item.cartItemId] = item.renderingConfig;
      return acc;
    }, {});
    const bookingsOptionsMap = cartGql.items.reduce((acc, item) => {
      acc[item.cartItemId] = item.optionsSelectionsValues;
      return acc;
    }, {});
    return {renderingConfigMap, bookingsOptionsMap};
  }

  public async fetchCartFromSDK(): Promise<void> {
    let cart: Cart;
    if (this.siteStore.experiments.enabled(SPECS.UseCurrentCartFromSdk)) {
      const shouldWarmupData = this.siteStore.isSSR();
      [cart] = await Promise.all([
        this.currentCartService.getCurrentCart({shouldWarmupData}),
        this.storeMetaDataService.init({shouldWarmupData}),
      ]);
    } else {
      cart = await this.currentCartService.getCurrentCart({shouldWarmupData: this.siteStore.isSSR()});
    }
    if (this.hasBookingsItem(cart)) {
      const {renderingConfigMap, bookingsOptionsMap} = await this.getEnrichedData();
      this.setCartModel(cart, renderingConfigMap, bookingsOptionsMap);
    } else {
      this.setCartModel(cart);
    }
  }

  public async isPreselectedNonShippingFlow(): Promise<boolean> {
    try {
      const estimatedTotals = await this.currentCartService.estimateCurrentCartTotals({
        calculateShipping: true,
        calculateTax: this.shouldCalculateTax(),
      });
      const isPickup =
        !!estimatedTotals.shippingInfo?.selectedCarrierServiceOption?.logistics?.pickupDetails?.address?.addressLine1;
      const hasTimeSlots = Boolean(
        (estimatedTotals.shippingInfo?.selectedCarrierServiceOption?.logistics as DeliveryLogisticsWithPrivateFields)
          ?.deliveryTimeSlot
      );
      return isPickup || hasTimeSlots;
    } catch (e) {
      await this.controllerApi.reportFedops(FedopsInteractions.errorInEstimateTotalsWithShipping);
      return false;
    }
  }

  public async fetchEstimateTotals(): Promise<void> {
    if (this.siteStore.experiments.enabled(SPECS.CatchEstimateTotalsError)) {
      try {
        const estimatedTotals = await this.currentCartService.estimateCurrentCartTotals({
          calculateShipping: this.shouldCalculateShipping(),
          calculateTax: this.shouldCalculateTax(),
        });
        this.setEstimatedTotalModel(estimatedTotals);
        this.errorOnEstimateTotals = false;
        await this.controllerApi.updateComponent();
      } catch {
        this.biService.cartEstimateTotalsErrorShown(this.cartModel, this.cartLayout);
        this.errorOnEstimateTotals = true;
        await this.controllerApi.updateComponent();
      }
    } else {
      const estimatedTotals = await this.currentCartService.estimateCurrentCartTotals({
        calculateShipping: this.shouldCalculateShipping(),
        calculateTax: this.shouldCalculateTax(),
      });
      this.setEstimatedTotalModel(estimatedTotals);
    }
  }

  public get hasPickupOption(): boolean {
    return (this.estimatedTotals?.shippingInfo?.shippingRule?.shippingOptions ?? [])
      .map(toShippingRuleOptionModel)
      .some((option) => option.isPickup);
  }

  private async sendCartLoadedBi() {
    return this.biService.viewCartPageSf({
      cartLayout: this.cartLayout,
      estimatedTotals: this.estimatedTotals,
      cartModel: this.cartModel,
      cartType: this.cartType,
      paymentMethods: (await this.storeMetaDataService.get())?.activePaymentMethods || [],
      numOfVisibleShippingOptions: this.estimatedTotals?.shippingInfo?.shippingRule?.shippingOptions.length,
      shouldShowCoupon: this.styleSettingsService.shouldShowCoupon,
      shouldShowBuyerNote: this.styleSettingsService.shouldShowBuyerNote,
      shouldShowContinueShopping: this.styleSettingsService.shouldRenderContinueShopping,
      shouldShowShipping: this.styleSettingsService.shouldShowShipping,
      shouldShowTax: this.styleSettingsService.shouldShowTax,
      hasPickupOption: this.hasPickupOption,
      isCheckoutButtonPresented: this.styleSettingsService.isCheckoutButtonShowInSomeBreakpoint,
      isViewCartButtonPresented: this.styleSettingsService.isViewCartButtonShownInSomeBreakpoint,
      hasGiftCardLineItem: this.cartModel.lineItemsTypes.includes(ProductType.GIFT_CARD),
    });
  }

  public async fetchCartAndEstimateTotals(isInitialLoad = false): Promise<void> {
    if (this.shouldFetchCartFromSdk()) {
      await this.fetchCartFromSDK();
      const shouldEstimateTotals = this.shouldEstimateTotals();
      this.isSummaryLoading = shouldEstimateTotals && isInitialLoad;
      this.isSummaryUpdating = shouldEstimateTotals;

      if (!this.siteStore.isSSR() && shouldEstimateTotals) {
        void this.fetchEstimateTotals().then(() => {
          this.isSummaryUpdating = false;
          this.isSummaryLoading = false;
          void this.controllerApi.updateComponent();
          if (this.siteStore.experiments.enabled(SPECS.SendCartLoadedAfterEstimate)) {
            void this.sendCartLoadedBi();
          }
        });
      } else {
        this.removeEstimatedTotals();
        void this.sendCartLoadedBi();
      }
    } else {
      const cartGql = await this.getCartGql();
      this.isSummaryUpdating = false;
      this.setCartGQLModel(cartGql);
      this.setGQLEstimatedTotalModel(cartGql);
      void this.sendCartLoadedBi();
    }
  }

  private shouldFetchCartFromSdk(): boolean {
    const {shouldShowShipping, shouldShowTax} = this.styleSettingsService;

    const shouldFetchForShippingOrTax =
      this.siteStore.experiments.enabled(SPECS.CartFromSDKWhenShowShippingOrShowTax) &&
      (shouldShowShipping || shouldShowTax);

    const shouldFetchForMS1 =
      this.siteStore.experiments.enabled(SPECS.CartFromSDKWhenShowShippingOrShowTax) &&
      this.siteStore.experiments.enabled(SPECS.UseCurrentCartFromSdk);

    return shouldFetchForShippingOrTax || shouldFetchForMS1;
  }

  private shouldCalculateTax(): boolean {
    const {shouldShowTax} = this.styleSettingsService;
    return shouldShowTax && !this.siteStore.priceSettings?.taxOnProduct;
  }

  private shouldCalculateShipping(): boolean {
    const {shouldShowShipping} = this.styleSettingsService;
    return shouldShowShipping && !this.isNonShippableCart;
  }

  private isCartWithDepositItem(): boolean {
    return this.cartModel.lineItems.some((lineItem) => lineItem.depositAmount);
  }

  private hasAnActiveSPI() {
    const {hasValidationsInCart, hasAdditionalFees} = this.storeMetaDataService.getEcommerceSettings();
    return hasValidationsInCart || (hasAdditionalFees && this.cartLayout === CartLayout.CART);
  }

  public readonly clearError = async () => {
    this.setHasErrorState(false);
    await this.controllerApi.updateComponent();
  };

  public readonly handleGeneralError = async (error: Error) => {
    this.clearLoadingItems();
    this.setHasErrorState(true);
    this.isSummaryUpdating = false;
    await this.controllerApi.updateComponent();
    this.biService.errorPresentedInCartSideCart(error.message, this.cartModel);
    setTimeout(() => {
      void (async () => {
        await this.clearError();
      })();
    }, 5000);
  };

  public shouldEstimateTotals(): boolean {
    if (!this.shouldFetchCartFromSdk()) {
      return false;
    }

    if (
      this.siteStore.experiments.enabled(SPECS.CartFromSDKWhenShowShippingOrShowTax) &&
      !this.siteStore.experiments.enabled(SPECS.UseCurrentCartFromSdk)
    ) {
      return this.cartModel.lineItems.length > 0;
    }

    const {isExpressCheckoutButtonShownInSomeBreakpoint} = this.styleSettingsService;

    return (
      this.cartModel.lineItems.length > 0 &&
      (this.shouldCalculateShipping() ||
        this.shouldCalculateTax() ||
        isExpressCheckoutButtonShownInSomeBreakpoint ||
        this.isCartWithDepositItem() ||
        this.siteStore.isEditorMode() ||
        this.hasAnActiveSPI())
    );
  }

  private getCartGqlForBookings() {
    return this.currentCartService.getCurrentCartGQL({
      withShipping: false,
      withTax: false,
    });
  }

  private getCartGql() {
    const {shouldShowShipping, shouldShowTax} = this.styleSettingsService;
    return this.currentCartService.getCurrentCartGQL({
      withShipping: shouldShowShipping,
      withTax: shouldShowTax,
    });
  }

  public get cartType(): CartType {
    const hasDigital = this.cartModel?.lineItems.some((lineItem) => lineItem.itemType === ProductType.DIGITAL);
    const hasPhysical = this.hasShippableItems;
    const hasService = this.cartModel?.lineItems.some((lineItem) => lineItem.itemType === ProductType.SERVICE);
    const hasGiftCard = this.cartModel?.lineItemsTypes.includes(ProductType.GIFT_CARD);
    const hasMultiVerticalItems = (hasDigital || hasPhysical) && (hasService || hasGiftCard);

    if (hasMultiVerticalItems) {
      return CartType.MIXED_VERTICALS;
    }

    /* istanbul ignore next */
    if (hasDigital && hasPhysical) {
      return CartType.MIXED;
    } else if (hasDigital) {
      return CartType.DIGITAL;
    } else if (hasPhysical) {
      return CartType.PHYSICAL;
    } else if (hasService) {
      return CartType.SERVICE;
    } else if (hasGiftCard) {
      return CartType.GIFT_CARD;
    } else {
      return CartType.UNRECOGNISED;
    }
  }

  public get isNonShippableCart(): boolean {
    return !this.hasShippableItems;
  }

  public get hasShippableItems(): boolean {
    return this.cartModel?.lineItems.some(
      (lineItem) => !lineItem.itemType || lineItem.itemType === ProductType.PHYSICAL
    );
  }

  public get isZeroCart(): boolean {
    if (this.estimatedTotals) {
      return this.estimatedTotals.priceSummary.total.convertedAmount === 0;
    } else {
      return this.cartModel.subtotalAfterDiscounts.convertedAmount === 0;
    }
  }

  public get isEmpty(): boolean {
    return !this.cartModel?.lineItems.length;
  }

  public get areAllItemsInStock(): boolean {
    return (
      this.cartModel?.lineItems &&
      this.cartModel.lineItems.every(
        (lineItem) => lineItem.quantityAvailable === undefined || lineItem.quantityAvailable > 0
      )
    );
  }

  public get isFullAddressRequired() {
    return (
      this.estimatedTotals?.shippingInfo?.status === ShippingRuleStatus.FullAddressRequired ||
      (this.estimatedTotals?.shippingInfo?.status === ShippingRuleStatus.MissingZipCode &&
        this.siteStore.experiments.enabled(SPECS.ShouldNotUseDestinationCompleteness))
    );
  }

  public get itemsCount(): number {
    return this.cartModel.lineItems.reduce((count, lineItem) => count + lineItem.quantity, 0);
  }

  public get hasErrorViolations(): boolean {
    return this.estimatedTotals?.violations?.some((violation) => violation.severity === ViolationSeverity.error);
  }

  public async createCheckout(): Promise<string | {error: string} | undefined> {
    if (this.siteStore.experiments.enabled(SPECS.UseCurrentCartFromSdk)) {
      this.controllerApi.reportStartFedops(FedopsInteractions.createCheckout);
      this.controllerApi.reportStartFedops(FedopsInteractions.createCheckoutWithError);
      try {
        const {checkoutId} = await this.currentCartService.createCheckout({channelType: ChannelType.WEB});
        this.controllerApi.reportEndFedops(FedopsInteractions.createCheckout);
        this.checkoutId = checkoutId;
        if (this.siteStore.experiments.enabled(SPECS.RemoveGQLCallOnCreateCheckout)) {
          return checkoutId;
        }
      } catch (error) {
        this.controllerApi.reportEndFedops(FedopsInteractions.createCheckoutWithError);
        if (
          (error as ApplicationError)?.details?.applicationError?.code ===
          CreateCheckoutExceptions.siteMustAcceptPayments
        ) {
          return {error: CreateCheckoutExceptions.siteMustAcceptPayments};
        }
        return this.siteStore.experiments.enabled(SPECS.FixHandleCreateCheckoutError)
          ? undefined
          : {error: CreateCheckoutExceptions.unrecognizedError};
      }
    }
    this.controllerApi.reportStartFedops(FedopsInteractions.createCheckoutOld);
    this.controllerApi.reportStartFedops(FedopsInteractions.createCheckoutOldWithError);
    return this.checkoutApi
      .createCheckout(this.cartModel.id)
      .then((id) => {
        this.controllerApi.reportEndFedops(FedopsInteractions.createCheckoutOld);
        return (this.checkoutId = id);
      })
      .catch((error) => {
        console.error(error);
        this.controllerApi.reportEndFedops(FedopsInteractions.createCheckoutOldWithError);
        return JSON.stringify(error)
          .toLowerCase()
          .includes(CreateCheckoutExceptions.siteMustAcceptPayments.toLowerCase())
          ? {error: CreateCheckoutExceptions.siteMustAcceptPayments}
          : undefined;
      });
  }

  public readonly updateItemQuantity = async (cartItemId: number, quantity: number): Promise<void> => {
    try {
      return await this.currentCartService.updateLineItemQuantity(
        // eslint-disable-next-line import/no-deprecated
        {lineItemId: intToGuid(cartItemId), quantity},
        {origin: this.origin}
      );
    } catch (e) {
      await this.handleGeneralError(e as Error);
    }
  };

  public readonly updateBuyerNote = async (content: string) => {
    try {
      await this.currentCartService.updateBuyerNote({buyerNote: content});
      this.biService.updateBuyerNote(this.cartModel, !!content);
    } catch (e) {
      await this.handleGeneralError(e as Error);
    }
  };

  public readonly removeItemFromCart = async (lineItem: LineItemModel): Promise<void> => {
    try {
      // eslint-disable-next-line import/no-deprecated
      return await this.currentCartService.removeLineItem({lineItemId: intToGuid(lineItem.id)}, {origin: this.origin});
    } catch (e) {
      await this.handleGeneralError(e as Error);
    }
  };

  public readonly setDestinationForEstimation = async ({
    country,
    subdivision,
    zipCode,
  }: {
    country: string;
    subdivision?: string;
    zipCode?: string;
  }): Promise<void> => {
    try {
      return await this.currentCartService.setAddress({address: {country, subdivision, zipCode}});
    } catch (e) {
      await this.handleGeneralError(e as Error);
    }
  };

  public readonly setShippingOption = async (options: {option: {code: string; carrierId: string}}): Promise<void> => {
    try {
      return await this.currentCartService.selectShippingOption(options);
    } catch (e) {
      await this.handleGeneralError(e as Error);
    }
  };

  public readonly clearCouponError = (): void => {
    this.couponError = null;
  };

  public readonly applyCoupon = async (couponCode: string): Promise<void> => {
    try {
      this.clearCouponError();
      this.isSummaryUpdating = true;
      await this.controllerApi.updateComponent();
      if (!couponCode) {
        const errorCode = 'ERROR_EMPTY_INPUT';
        this.biService.errorWhenApplyingACouponSf(this.cartModel, couponCode, errorCode);
        this.couponError = {
          code: errorCode,
          message: '',
        };
        throw new Error(errorCode);
      }

      const applyCouponPromise = this.currentCartService.applyCoupon({code: couponCode}, {origin: this.origin});

      await applyCouponPromise.catch((e) => {
        if (this.siteStore.experiments.enabled(SPECS.UseCurrentCartFromSdk)) {
          const errorCode =
            e?.details?.applicationError?.code ??
            e?.validationError?.fieldViolations?.[0]?.ruleName ??
            'ERROR_COUPON_GENERAL_ERROR';
          this.couponError = {
            code: errorCode,
            message: e?.details?.applicationError?.description,
          };
          this.biService.errorWhenApplyingACouponSf(this.cartModel, couponCode, errorCode);
        } else if (e.success === false) {
          const errorCode = e.errors[0].code;
          this.biService.errorWhenApplyingACouponSf(this.cartModel, couponCode, errorCode);
          this.couponError = {
            code: errorCode,
            message: e.errors[0].message,
          };
        }
        throw e;
      });
    } catch {
      this.isSummaryUpdating = false;
      return this.controllerApi.updateComponent();
    }
  };

  public readonly removeCoupon = async (): Promise<void> => {
    try {
      return await this.currentCartService.removeCoupon({origin: this.origin});
    } catch (e) {
      await this.handleGeneralError(e as Error);
    }
  };

  public readonly setHasErrorState = (value: boolean) => (this.hasError = value);

  private readonly setCartGQLModel = (cart: ICartFromCartApi) =>
    (this.cartModel = CartModel.fromGQL(cart, this.siteStore.experiments.enabled(SPECS.GiftCardAddToCartSettings)));
  private readonly setCartModel = (
    cart: Cart,
    renderingConfigMap?: Record<string, IRenderingConfig>,
    bookingsOptionsMap?: Record<string, IOptionsSelectionsValue[]>
  ) =>
    (this.cartModel = CartModel.fromSDK(
      cart as CartWithPrivateFields,
      renderingConfigMap,
      bookingsOptionsMap,
      this.siteStore.experiments.enabled(SPECS.GiftCardAddToCartSettings)
    ));
  private readonly setGQLEstimatedTotalModel = (cart: ICartFromCartApi) =>
    (this.estimatedTotals = EstimatedTotalsModel.fromGQL(cart));
  private readonly setEstimatedTotalModel = (estimatedTotals: EstimateTotalsResponse) =>
    (this.estimatedTotals = EstimatedTotalsModel.fromSDK(estimatedTotals));
  private readonly removeEstimatedTotals = () => (this.estimatedTotals = undefined);
}
