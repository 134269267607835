import {MultiCurrencyPrice} from '@wix/ecom_current-cart';

export class PriceModel {
  public convertedAmount: number;
  public formattedConvertedAmount: string;

  constructor(params: PriceModel) {
    this.convertedAmount = params.convertedAmount;
    this.formattedConvertedAmount = params.formattedConvertedAmount;
  }

  public static fromGQL({
    convertedAmount,
    formattedConvertedAmount,
  }: {
    convertedAmount?: number;
    formattedConvertedAmount?: string;
  }) {
    return new PriceModel({
      convertedAmount,
      formattedConvertedAmount,
    });
  }

  public static fromSDK(multiCurrencyPrice: MultiCurrencyPrice) {
    return new PriceModel({
      convertedAmount: parseFloat(multiCurrencyPrice?.convertedAmount),
      formattedConvertedAmount: multiCurrencyPrice?.formattedConvertedAmount,
    });
  }
}
