export const SPECS = {
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  ShouldUseOpenSideCartApiInAddToCart: 'specs.stores.ShouldUseOpenSideCartApiInAddToCart',
  ShouldUseEcomPlatformPublicApiAddToCartInPP: 'specs.stores.ShouldUseEcomPlatformPublicApiAddToCartInPP',
  AddSidePaddingOnCartPageForDesktopViewer: 'specs.stores.AddSidePaddingOnCartPageForDesktopViewer',
  AddSlotToCartPage: 'specs.stores.AddSlotToCartPage',
  ExpressCheckoutInSideCart: 'specs.stores.ExpressCheckoutInSideCart',
  ShowCssPerBreakpointIndicationsSideCart: 'specs.stores.ShowCssPerBreakpointIndicationsSideCart',
  UseAllStylesInCart: 'specs.stores.UseAllStylesInCart',
  SideCartElementsVisibilityInCss: 'specs.stores.SideCartElementsVisibilityInCss',
  CheckoutFromSDKInExpressCheckoutButtons: 'specs.stores.CheckoutFromSDKInExpressCheckoutButtons',
  CartFromSDKWhenShowShippingOrShowTax: 'specs.stores.CartFromSDKWhenShowShippingOrShowTax',
  UseCurrentCartFromSdk: 'specs.stores.UseCurrentCartFromSdk',
  ShouldNotUseDestinationCompleteness: 'specs.stores.ShouldNotUseDestinationCompleteness',
  MapCartSummaryColors: 'specs.stores.MapCartSummaryColors',
  CheckoutButtonLinkedToSecureCheckout: 'specs.stores.CheckoutButtonLinkedToSecureCheckout',
  FixLoadingCartInEditorAndPreview: 'specs.stores.FixLoadingCartInEditorAndPreview',
  GiftCardAddToCartSettings: 'specs.stores.ShowGiftCardAddToCartSettings',
  FixRedundantGetAfterUpdate: 'specs.stores.FixRedundantGetAfterUpdate',
  HideDepositWhenZero: 'specs.stores.HideDepositWhenZero',
  SendCartLoadedAfterEstimate: 'specs.stores.SendCartLoadedAfterEstimate',
  CatchEstimateTotalsError: 'specs.stores.CatchEstimateTotalsError',
  UseProductThumbnailWithWowImage: 'specs.stores.UseProductThumbnailWithWowImage',
  UseSkeletonLineFromWUT: 'specs.stores.UseSkeletonLineFromWUT',
  FixHandleCreateCheckoutError: 'specs.ecom.FixHandleCreateCheckoutError',
  FixCustomLineItemImage: 'specs.ecom.FixCustomLineItemImage',
  RemoveGQLCallOnCreateCheckout: 'specs.ecom.RemoveGQLCallOnCreateCheckout',
  RefactorCartCouponSection: 'specs.ecom.RefactorCartCouponSection',
  HandleNoCheckoutIdError: 'specs.ecom.HandleNoCheckoutIdError',
} as const;
