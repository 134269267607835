import {StyleParam} from '../../common/constants';
import {ICartStyleSettings} from '../../types/app.types';
import {CheckoutButtonsPosition} from '@wix/wixstores-client-core';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {applyDefaults} from '../utils/applyDefaults';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from '../specs';

export class StyleSettingsService {
  private styleSettings: ICartStyleSettings;
  private readonly allStyles?: ICartStyleSettings[];
  constructor(
    controllerParams: ControllerParams,
    defaultSettings: ICartStyleSettings,
    private readonly siteStore: SiteStore
  ) {
    this.styleSettings = applyDefaults(
      defaultSettings,
      controllerParams.controllerConfig.config.style.styleParams as unknown as ICartStyleSettings
    );
    if (this.siteStore.experiments.enabled(SPECS.UseAllStylesInCart)) {
      this.allStyles = (controllerParams.controllerConfig.config.allStyles as unknown as ICartStyleSettings[]).map(
        (style) => applyDefaults(defaultSettings, style)
      );
    }
  }

  public update(styleSettings: ICartStyleSettings) {
    this.styleSettings = styleSettings;
  }

  public get shouldRenderContinueShopping() {
    return this.styleSettings.booleans[StyleParam.ShowContinueShopping] === true;
  }

  public get shouldShowCoupon() {
    return this.styleSettings.booleans[StyleParam.ShowCoupon] === true;
  }

  public get shouldShowBuyerNote() {
    return this.styleSettings.booleans[StyleParam.ShowBuyerNote] === true;
  }

  public get shouldShowTax() {
    return this.styleSettings.booleans[StyleParam.ShowTax] === true;
  }

  public get shouldShowShipping() {
    return this.styleSettings.booleans[StyleParam.ShowShipping] === true;
  }

  public get dynamicPaymentMethodsTheme() {
    return this.styleSettings.numbers[StyleParam.dynamicPaymentMethodsTheme];
  }
  public get dynamicPaymentMethodsShape() {
    return this.styleSettings.numbers[StyleParam.dynamicPaymentMethodsShape];
  }

  public get isEditorX() {
    return this.styleSettings.booleans[StyleParam.Responsive] === true;
  }

  public get shouldShowTopCheckoutButtonInMobile() {
    return (
      this.styleSettings.numbers[StyleParam.CheckoutButtonsPosition] ===
      Number(CheckoutButtonsPosition.ABOVE_AND_BELOW_CART)
    );
  }

  private isTrueInSomeBreakpoint(buttonStyleParam: StyleParam): boolean {
    if (this.siteStore.experiments.enabled(SPECS.UseAllStylesInCart)) {
      return this.allStyles.some(({booleans}) => booleans[buttonStyleParam]);
    }
    return this.styleSettings.booleans[buttonStyleParam] === true;
  }

  public get isCheckoutButtonShowInSomeBreakpoint() {
    return this.isTrueInSomeBreakpoint(StyleParam.ShowCheckoutButton);
  }

  public get isViewCartButtonShownInSomeBreakpoint() {
    return this.isTrueInSomeBreakpoint(StyleParam.ShowGoToCartButton);
  }

  public get isExpressCheckoutButtonShownInSomeBreakpoint() {
    return this.isTrueInSomeBreakpoint(StyleParam.ShowExpressCheckout);
  }
}
