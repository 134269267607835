import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings';
import {ISettingsParams} from '../../domain/utils/settings.utils';

export type ICartPublicData = Partial<{[k in keyof ISettingsParams<typeof settingsParams>]: string | {}}>;

export const settingsParams = {
  CART_TITLE: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('cart.shopping_cart_title'),
    inheritFromAppScope: true,
  },
  CART_EMPTY_CART: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('cart.empty_cart_title'),
    inheritFromAppScope: true,
  },
  CART_CONTINUE_SHOPPING_LINK: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('cart.continue_shopping'),
    inheritFromAppScope: true,
  },
  CART_CONTINUE_SHOPPING_LINK_OBJECT: {
    type: SettingsParamType.Object,
  },
  CART_ADD_NOTE_LINK: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('cart.buyer_note_add_button_label'),
    inheritFromAppScope: true,
  },
  CART_INSTRUCTIONS_TEXT: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('cart.buyer_note_placeholder'),
    inheritFromAppScope: true,
  },
  CART_ADD_PROMO_CODE_LINK: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('cart.coupon_add_button_label'),
    inheritFromAppScope: true,
  },
  CART_BUTTON_TEXT: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('cart.secure_checkout'),
    inheritFromAppScope: true,
  },
  CART_V2_DISCLAIMER: {
    type: SettingsParamType.String,
    getDefaultValue: (): null => null,
    inheritFromAppScope: true,
  },
};

export default createSettingsParams<Omit<ISettingsParams<typeof settingsParams>, 'CART_CONTINUE_SHOPPING_LINK_OBJECT'>>(
  settingsParams
);
