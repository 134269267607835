import {Address} from '@wix/ecom_current-cart';

export class AddressModel {
  public country?: string;
  public countryFullname: string;
  public subdivision?: string;
  public subdivisionFullname: string;
  public city?: string;
  public addressLine?: string;
  public postalCode: string;

  constructor(params: AddressModel) {
    this.country = params.country;
    this.countryFullname = params.countryFullname;
    this.subdivision = params.subdivision;
    this.subdivisionFullname = params.subdivisionFullname;
    this.city = params.city;
    this.addressLine = params.addressLine;
    this.postalCode = params.postalCode;
  }

  public static fromGQL(address: {
    country?: string;
    countryName?: string;
    subdivision?: string;
    subdivisionName?: string;
    city?: string;
    addressLine?: string;
    zipCode?: string;
  }) {
    return new AddressModel({
      country: address?.country,
      countryFullname: address?.countryName,
      subdivision: address?.subdivision,
      subdivisionFullname: address?.subdivisionName,
      city: address?.city,
      addressLine: address?.addressLine,
      postalCode: address?.zipCode,
    });
  }

  public static fromSDK(address: Address) {
    return new AddressModel({
      country: address?.country,
      countryFullname: address?.countryFullname,
      subdivision: address?.subdivision,
      subdivisionFullname: address?.subdivisionFullname,
      city: address?.city,
      addressLine: 'addressLine',
      postalCode: address?.postalCode,
    });
  }
}
