import {StoreMetaDataService} from '../services/StoreMetaDataService';

export class PaymentMethodStore {
  private isCheckoutRegularFlowEnabled: boolean = false;
  private hasLoadedPaymentMethods: boolean = false;
  private readonly storeMetaDataService: StoreMetaDataService;
  constructor({storeMetaDataService}: {storeMetaDataService: StoreMetaDataService}) {
    this.storeMetaDataService = storeMetaDataService;
  }
  public async init() {
    const [hasRegularFlowPaymentMethods, hasNoPaymentMethods, hasEWalletPaymentMethods] = await Promise.all([
      this.storeMetaDataService.hasRegularFlowPaymentMethods(),
      this.storeMetaDataService.hasAnyPaymentMethods().then((hasAnyPaymentMethods) => !hasAnyPaymentMethods),
      this.storeMetaDataService.hasEWalletPaymentMethods(),
    ]);

    this.isCheckoutRegularFlowEnabled = hasRegularFlowPaymentMethods || hasNoPaymentMethods || hasEWalletPaymentMethods;
    this.hasLoadedPaymentMethods = true;
    return this.isCheckoutRegularFlowEnabled;
  }

  public toProps() {
    return {
      hasLoadedPaymentMethods: this.hasLoadedPaymentMethods,
      isCheckoutRegularFlowEnabled: this.isCheckoutRegularFlowEnabled,
    };
  }
}
