import {DescriptionLine} from '@wix/ecom_current-cart';

export class DescriptionLineModelModel {
  public title: string;
  public value: string;

  constructor(params: DescriptionLineModelModel) {
    this.title = params.title;
    this.value = params.value;
  }

  public static fromGQL(title: string, value: string) {
    return new DescriptionLineModelModel({
      title,
      value,
    });
  }

  public static fromSDK(descriptionLine: DescriptionLine) {
    return new DescriptionLineModelModel({
      title: descriptionLine?.name?.translated ?? descriptionLine?.name?.original,
      value: descriptionLine.colorInfo ? getColorInfoValue(descriptionLine) : getPlainTextValue(descriptionLine),
    });
  }
}

const getPlainTextValue = ({plainText}: DescriptionLine) => plainText?.translated ?? plainText?.original;

const getColorInfoValue = ({colorInfo}: DescriptionLine) => colorInfo?.translated ?? colorInfo?.original;
