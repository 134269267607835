import {PriceModel} from './Price.model';

export class CostModel {
  public price: PriceModel;

  constructor(params: CostModel) {
    this.price = params.price;
  }

  public static fromGQL({formattedConvertedAmount}: {formattedConvertedAmount: string}) {
    return new CostModel({
      price: PriceModel.fromGQL({formattedConvertedAmount}),
    });
  }
}
