import {IRenderingConfig} from '../../types/app.types';

/**
 * @deprecated
 */
export class RenderingConfigModel {
  public hideQuantity: boolean;
  public hidePrice: boolean;

  constructor(params: RenderingConfigModel) {
    this.hideQuantity = params.hideQuantity;
    this.hidePrice = params.hidePrice;
  }

  public static fromGQL(renderingConfig: IRenderingConfig) {
    return new RenderingConfigModel({
      hideQuantity: renderingConfig.hideQuantity,
      hidePrice: renderingConfig.hidePrice,
    });
  }
}
