import {ReadOnlyExperiments} from '@wix/wix-experiments';
import {VeloInputs} from '../../../types/app.types';

export function wixcodePublicApi(experiments: ReadOnlyExperiments, setVeloInputs: (x: VeloInputs) => Promise<void>) {
  return {
    showSecureCheckout: async () => {
      await setVeloInputs({shouldShowSecureCheckout: true});
    },
    hideSecureCheckout: async () => {
      await setVeloInputs({shouldShowSecureCheckout: false});
    },
  };
}
